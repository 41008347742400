<template>
  <div class="card p-10">
    <div
      class="
        notice
        d-flex
        bg-light-warning
        rounded
        border-warning border border-dashed
        mb-10
        p-6
      "
    >
      <span class="svg-icon svg-icon-2tx svg-icon-warning me-4">
        <inline-svg src="media/icons/duotune/general/gen044.svg" />
      </span>
      <!--begin::Wrapper-->
      <div class="d-flex flex-stack flex-grow-1">
        <!--begin::Content-->
        <div class="fw-bold">
          <h4 class="text-gray-800 fw-bolder">價格方案設定說明</h4>
          <div class="fs-4 text-gray-600">
            1. 下方星期文字左方可開關此天是否開放 <br />
            2. 下方星期文字右方可操作開放時間 <br />
            3. 點選提交後即可完成設定
          </div>
        </div>
        <!--end::Content-->
      </div>
      <!--end::Wrapper-->
    </div>
    <div class="d-flex justify-content-end">
      <button @click="dataToShowData()" type="button" class="btn btn-warning">
        復原
      </button>
      <button @click="onSubmit()" type="button" class="ms-3 btn btn-primary">
        提交
      </button>
    </div>

    <div class="setting-box">
      <template v-for="i in 7" :key="i">
        <div :class="[!weekSwitch[`${i}`] && 'disabledWeekItem']">
          <div class="week-text">
            <el-switch v-model="weekSwitch[`${i}`]" class="me-2" />{{
              weekTranslate[`${i}`]
            }}
          </div>
          <div class="timeRange">
            <el-tag
              v-for="tag in weekData[`${i}`]"
              :key="tag.key"
              class="mx-1 ms-2"
              closable
              type="info"
              @close="closeTag(`${i}`, tag)"
            >
              {{ tag.value }}
            </el-tag>
          </div>
          <div>
            <button
              type="button"
              class="btn btn-primary btn-sm"
              @click="openAddRange(`${i}`)"
              :disabled="!weekSwitch[`${i}`]"
            >
              新增區間
            </button>
          </div>
        </div>
      </template>
    </div>
  </div>
  <el-dialog v-model="addRangeVisible" title="新增區間" width="25%">
    <el-form
      :model="addRangeForm"
      :rules="rules"
      ref="addRangeRef"
      class="form"
    >
      <div class="d-flex">
        <el-form-item prop="selectStartTime">
          <el-time-select
            v-model="addRangeForm.selectStartTime"
            :max-time="addRangeForm.selectEndTime"
            class="mr-4"
            placeholder="區間起始"
            start="00:00"
            step="00:15"
            end="24:00"
          />
        </el-form-item>
        <el-form-item prop="selectEndTime">
          <el-time-select
            class="ms-2"
            v-model="addRangeForm.selectEndTime"
            :min-time="addRangeForm.selectStartTime"
            placeholder="區間結束"
            start="00:00"
            step="00:15"
            end="24:00"
          />
        </el-form-item>
      </div>
    </el-form>

    <div class="d-flex justify-content-end mt-3">
      <button @click="addRange()" type="button" class="ms-6 btn btn-primary">
        新增
      </button>
    </div>
  </el-dialog>
</template>

<script lang='ts'>
import moment from "moment";
import { defineComponent, reactive, ref, watch } from "vue";
import { useRoute } from "vue-router";
import {
  getChargePlanTimeRule,
  updateTimeRule,
} from "@/core/services/api/chargePlan";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  setup() {
    const addRangeRef = ref<null | HTMLFormElement>(null);
    const route = useRoute();
    const chargePlanGuid = route.params.chargePlanGuid;
    const value2 = ref(false);
    const selectStartTime = ref("");
    const selectEndTime = ref("");
    const addRangeVisible = ref(false);
    const weekTranslate = reactive({
      "1": "星期一",
      "2": "星期二",
      "3": "星期三",
      "4": "星期四",
      "5": "星期五",
      "6": "星期六",
      "7": "星期日",
    });
    const weekMap = reactive({
      Monday: "1",
      Tuesday: "2",
      Wednesday: "3",
      Thursday: "4",
      Friday: "5",
      Saturday: "6",
      Sunday: "7",
    });
    const weekData = reactive({
      "1": [],
      "2": [],
      "3": [],
      "4": [],
      "5": [],
      "6": [],
      "7": [],
    });
    // 周一至周日各開放與否
    const weekSwitch = reactive({
      "1": true,
      "2": true,
      "3": true,
      "4": true,
      "5": true,
      "6": true,
      "7": true,
    });
    const currentSetWeek = ref("");
    const timeRule: any = reactive([]);
    const rules = reactive({
      selectStartTime: [
        {
          required: true,
          message: "請輸入區間起始",
          trigger: "change",
        },
      ],
      selectEndTime: [
        {
          required: true,
          message: "請輸入區間結束",
          trigger: "change",
        },
      ],
    });
    const chargePlan: any = reactive({});
    const addRangeForm = reactive({
      selectStartTime: "",
      selectEndTime: "",
    });

    const openAddRange = (week) => {
      addRangeVisible.value = true;
      currentSetWeek.value = week;
    };

    const addRange = () => {
      addRangeRef.value!.validate((valid) => {
        if (valid) {
          if (verifyAddRange()) {
            weekData[currentSetWeek.value].push({
              key: Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
              startTime: addRangeForm.selectStartTime,
              endTime: addRangeForm.selectEndTime,
              value: `${addRangeForm.selectStartTime}~${addRangeForm.selectEndTime}`,
            });
            addRangeVisible.value = false;
          } else {
            alert("日期重疊");
          }
        }
      });
    };

    const closeTag = (week, item) => {
      weekData[week] = weekData[week].filter((o) => o.key !== item.key);
    };

    watch(
      () => addRangeVisible.value,
      () => {
        addRangeForm.selectStartTime = "";
        addRangeForm.selectEndTime = "";
      }
    );

    // 驗證新增時間
    const verifyAddRange = () => {
      if (weekData[currentSetWeek.value].length > 0) {
        for (const data of weekData[currentSetWeek.value]) {
          const startTime = moment(addRangeForm.selectStartTime, "HH:mm");
          const endTime = moment(addRangeForm.selectEndTime, "HH:mm");

          const dataStartTime = moment(data.startTime, "HH:mm");
          const dataEndTime = moment(data.endTime, "HH:mm");

          const v1 = startTime.isBefore(dataEndTime);
          const v2 = endTime.isAfter(dataStartTime);

          return !(v1 && v2);
        }
      }
      return true;
    };

    // 監測選擇天，變動則清除當天開放時間
    watch(
      () => weekSwitch,
      () => {
        for (const key in weekData) {
          if (!weekSwitch[key]) {
            weekData[key] = [];
          }
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );

    // 取得時間規則
    const setTimeRule = async () => {
      let response = await getChargePlanTimeRule(chargePlanGuid);
      timeRule.splice(0, timeRule.length, ...response);
    };

    // 根據分鐘取得小時
    const hourToShow = (minute) => {
      return minute / 60 < 10
        ? `0${Math.floor(minute / 60)}`
        : Math.floor(minute / 60);
    };

    // 根據分鐘取得分
    const minuteToShow = (minute) => {
      return minute % 60 < 10 ? `0${minute % 60}` : minute % 60;
    };

    // 分鐘切片轉顯示時間
    const intervalToShowDate = (interval) => {
      let startTime = `${hourToShow(interval.beginMinutes)}:${minuteToShow(
        interval.beginMinutes
      )}`;
      let endTime = `${hourToShow(interval.endMinutes)}:${minuteToShow(
        interval.endMinutes
      )}`;
      return {
        key: Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
        startTime: startTime,
        endTime: endTime,
        value: `${startTime}~${endTime}`,
      };
    };

    // 資料轉換為顯示規則
    const dataToShowData = () => {
      for (const item of timeRule) {
        weekSwitch[weekMap[item.weekDay]] = item.type == "Open";
        weekData[weekMap[item.weekDay]].splice(0);
        for (const interval of item.intervals) {
          if (interval.status == "Open") {
            weekData[weekMap[item.weekDay]].push(intervalToShowDate(interval));
          }
        }
      }
    };

    const getWeekByNum = (weekNum) => {
      for (const key in weekMap) {
        if (weekMap[key] == weekNum) {
          return key;
        }
      }
    };

    const getMinuteByShowData = (data) => {
      let hour = data.split(":")[0];
      let minute = data.split(":")[1];
      return Number(hour) * 60 + Number(minute);
    };

    const onSubmit = async () => {
      let request: any = [];
      for (const key in weekData) {
        let intervals: any = [];
        for (const item of weekData[key]) {
          intervals.push({
            status: "Open",
            beginMinutes: getMinuteByShowData(item.startTime),
            endMinutes: getMinuteByShowData(item.endTime),
          });
        }
        request.push({
          type: weekSwitch[key] ? "Open" : "None",
          weekDay: getWeekByNum(key),
          intervals: intervals,
        });
      }
      await updateTimeRule(chargePlanGuid!, request).then(async () => {
        await Swal.fire("提交成功!", "", "success");
      });
    };

    const init = async () => {
      await setTimeRule();
      dataToShowData();
      console.log("timeRule", timeRule);
    };
    init();

    return {
      value2,
      addRangeVisible,
      openAddRange,
      addRange,
      weekData,
      closeTag,
      weekSwitch,
      weekTranslate,
      chargePlanGuid,
      init,
      dataToShowData,
      onSubmit,
      addRangeRef,
      rules,
      addRangeForm,
    };
  },
});
</script>

<style scoped>
.setting-box > div {
  display: flex;
  align-items: center;
  height: 60px;
}

.week-text {
  font-size: 22px;
  font-weight: 600;
}
.timeRange {
  width: 500px;
}
.disabledWeekItem {
  background-color: rgb(185, 185, 185);
}
</style>